import { withPreview } from 'gatsby-source-prismic';
import React from 'react';
import styled from 'styled-components';

const PageNotFoundStyles = styled.main`
  max-width: 64ch;
  margin: 4rem auto 4rem 0px;

  * + * {
    margin-top: 24px;
  }
`;

const PageNotFound = () => (
  <PageNotFoundStyles className="page-not-found-parent">
    <h1>No page here. Must be down at the beach.</h1>
    <p>
      Let's get you back on track — <a href="/">Return to homepage</a>
    </p>
  </PageNotFoundStyles>
);

export default withPreview(PageNotFound);
